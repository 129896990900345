(function ($) {
    'use strict' // Start of use strict

    // Smooth scrolling using jQuery easing
    $('a.js-scroll-trigger[href*="#"]:not([href="#"])').click(function () {
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
            var target = $(this.hash)
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']')
            if (target.length) {
                $('html, body').animate({
                    scrollTop: (target.offset().top - 70),
                }, 1000, 'easeInOutExpo')
                return false
            }
        }
    })

    // Closes responsive menu when a scroll trigger link is clicked
    $('.js-scroll-trigger').click(function () {
        $('.navbar-collapse').collapse('hide')
    })

    // Activate scrollspy to add active class to navbar items on scroll
    $('body').scrollspy({
        target: '#mainNav',
        offset: 75,
    })

    // Collapse Navbar
    var navbarCollapse = function () {
        if ($('#mainNav').offset().top > 50) {
            $('#mainNav').addClass('navbar-scrolled py-1').removeClass('py-3')
        } else {
            $('#mainNav').removeClass('navbar-scrolled py-1').addClass('py-3')
        }
    }
    // Collapse now if page is not at top
    navbarCollapse()
    // Collapse the navbar when page is scrolled
    $(window).scroll(navbarCollapse)

})(jQuery) // End of use strict

//Tiny Slider
// tns({
//     container: '.customers',
//     // mode: 'carousel',
//     // axis: 'horizontal',
//     items: 7,
//     // gutter: 0,
//     // edgePadding: 0,
//     // fixedWidth: false,
//     // autoWidth: false,
//     // viewportMax: false,
//     // slideBy: 1,
//     // center: false,
//     controls: false,
//     // controlsPosition: 'top',
//     // controlsText: ['prev', 'next'],
//     // controlsContainer: false,
//     // prevButton: false,
//     // nextButton: false,
//     nav: false,
//     // navPosition: 'top',
//     // navContainer: false,
//     // navAsThumbnails: false,
//     // arrowKeys: false,
//     // speed: 300,
//     autoplay: true,
//     // autoplayPosition: 'top',
//     // autoplayTimeout: 5000,
//     // autoplayDirection: 'forward',
//     // autoplayText: ['start', 'stop'],
//     // autoplayHoverPause: false,
//     autoplayButton: false,
//     // autoplayButtonOutput: true,
//     // autoplayResetOnVisibility: true,
//     // animateIn: 'tns-fadeIn',
//     // animateOut: 'tns-fadeOut',
//     // animateNormal: 'tns-normal',
//     // animateDelay: false,
//     // loop: true,
//     // rewind: false,
//     // autoHeight: false,
//     // responsive: false,
//     // lazyload: false,
//     // lazyloadSelector: '.tns-lazy-img',
//     // touch: true,
//     mouseDrag: true,
//     // swipeAngle: 15,
//     // nested: false,
//     // preventActionWhenRunning: false,
//     // preventScrollOnTouch: false,
//     // freezable: true,
//     // onInit: false,
//     // useLocalStorage: true
// });
//
// tns({
//     container: '.partners',
//     items: 3,
//     controls: false,
//     nav: false,
//     autoplay: true,
//     autoplayButton: false,
//     mouseDrag: true,
//     speed: 100,
// });
tns({
    container: '.clients',
    items: 3,
    responsive: {
        '810': {
            'items': 7,
        },
        '500': {
            'items': 4,
        },
    },
    controls: false,
    nav: false,
    autoplay: true,
    autoplayButton: false,
    mouseDrag: true,
    autoHeight: true,
    autoplayTimeout: 3000,
    speed: 200,
})
tns({
    container: '.vendors',
    items: 3,
    responsive: {
        '810': {
            'items': 7,
        },
        '500': {
            'items': 4,
        },
    },
    controls: false,
    nav: false,
    autoplay: true,
    autoplayButton: false,
    mouseDrag: true,
    autoHeight: true,
    autoplayTimeout: 3000,
    speed: 200,
});

// Anime JS
// Wrap every letter in a span
var textWrapper = document.querySelector('.ml2')
textWrapper.innerHTML = textWrapper.textContent.replace(/\S/g, '<span class=\'letter\'>$&</span>')
anime.timeline({loop: false}).add({
    targets: '.ml2 .letter',
    scale: [4, 1],
    opacity: [0, 1],
    translateZ: 0,
    easing: 'easeOutExpo',
    duration: 950,
    delay: function (el, i) {
        return 70 * i
    },
});

// ParticlesJS
// particlesJS.load(@dom-id, @path-json, @callback (optional));
particlesJS.load('particles-js', 'bundle/vendor/particles.json', function () {
    // console.log('callback - particles.js config loaded');
});

// Graph
// global TimelineMax, topic:true, elements:true
var topics = {
    'unifiedthreatmanagement': '/',
    'endpointencryption': '/',
    'emailsecurity': '/',
    'webapplicationfirewalls': '/',
    'identity_accessmanagement': '/',
    'webvulnerabilityscanner': '/',
    'privilegeaccountmanagement': '/',
    'intrusionpreventionsystems': '/',
    'dataporotectionsolution': '/',
};

function openSlug(slug) {
    var url = 'http://pstechnology.ae' + slug;
    var win = window.open(url, '_self');
    if (win) {
        win.focus()
    } else {
        location.href = url
    }
}

// Each node contains
// #id-node The Icon/Shape for the actual node
// #id-text A group for the descriping node text
// #id-rect The shape containing icon and text (click area)
// configuring pipeline
var masterTimeline = new TimelineMax({
    delay: 1,
    repeat: -1,
})
// console.log(topics);
for (var topic in topics) {
    var clickArea = document.getElementById(topic + '-rect')
    var text = document.getElementById(topic + '-txt')
    var icon = document.getElementById(topic + '-node')
    var textOpacity = 0.2
    text.style.opacity = textOpacity
    var elements = [clickArea, text, icon]
    for (var i = 0; i < elements.length; i++) {
        var element = elements[i]
        element.onclick = function (slug) {
            openSlug(slug)
        }.bind(null, topics[topic])
        element.style.cursor = 'pointer'
        element.addEventListener('mouseover', function (hoverIcon, hoverText, iconOpacity) {
            masterTimeline.pause(0)
            var scale = 1.5
            if (iconOpacity != null) {
                scale = 1.5
            }
            var tl = new TimelineMax()
            tl.to(hoverIcon, 0.25, {
                scale: scale,
                transformOrigin: 'center center',
            }, 0).to(hoverIcon, 0.25, {
                opacity: '0.5',// originally 1
            }, 0).to(hoverText, 0.25, {
                opacity: '1',
                scale: '1.1',
                transformOrigin: 'center center',
            }, 0)
        }.bind(null, icon, text, icon.getAttribute('opacity')))
        element.addEventListener('mouseout', function (hoverIcon, hoverText, iconOpacity) {
            if (iconOpacity == null) {
                iconOpacity = 0.09
            }
            var tl = new TimelineMax()
            tl.to(hoverIcon, 0.25, {
                scale: 1,
                transformOrigin: 'center center',
            }, 0).to(hoverIcon, 0.25, {
                opacity: iconOpacity,
            }, 0).to(hoverText, 0.25, {
                opacity: textOpacity,
                scale: '1',
                transformOrigin: 'center center',
            }, 0)
            masterTimeline.play()
        }.bind(null, icon, text, icon.getAttribute('opacity')))
    }
    var opacity = icon.getAttribute('opacity')
    var scale = 1.5
    if (opacity == null) {
        var tl = new TimelineMax({
            yoyo: true,
            repeat: 3,
        })
        tl.to(icon, 0.2, {// Originally 0.5
            scale: scale,
            transformOrigin: 'center center',
        }, 0).to(icon, 0.2, {
            opacity: '0.7',
        }, 0).to(text, 0.2, {
            opacity: '0.7',
            scale: '1.1',
            transformOrigin: 'center center',
        }, 0).delay(1.5)
        masterTimeline.add(tl)
    }
}
